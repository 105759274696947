import React, { useEffect, useMemo, useState } from 'react'
import { DownloadIcon, IconButton, IconButtonKind } from '@aposphaere/ui-components'
import { useGoalsMutation, useGoalsQuery, useQuartersQuery } from '../../../hooks/graphql'
import { DashboardStructureType, useDashboardContext } from '../../../contexts/dashboardContext'
import { useCrmContext } from '../../../contexts/crmContext'
import { WHOLE_GERMANY_ID } from '../constants'
import { format, isAfter, isBefore, isPast } from 'date-fns'
import { DataForDownload } from '../../../hooks/useDataDashboardsDownload'
import { generateCSVDataCRMAppointments, generateCSVDataPharmacies, generateCSVDataTrainings } from '../csvData'
import { parseUTC } from '@aposphaere/core-kit'

type Props = {
  title: string
  projectId?: number
  summaryValue?: string | number
  denominatorValue?: string | number
  data: { label: string; value: string | number | undefined; textColorClass?: string }[]
  onDownloadClick?: () => void
  withGoals?: boolean
  dataForDownload?: DataForDownload
}

export const InfoContainer = ({
  title,
  summaryValue,
  denominatorValue,
  data,
  projectId = 0,
  onDownloadClick,
  withGoals = false,
  dataForDownload,
}: Props) => {
  const [goalValue, setGoalValue] = useState('0')
  const { mutate: createGoal } = useGoalsMutation()
  const {
    [DashboardStructureType.GENERAL]: { clusterSelected, dates },
  } = useDashboardContext()

  const { data: quarters } = useQuartersQuery()

  const enabled = clusterSelected !== WHOLE_GERMANY_ID && withGoals

  const variables = {
    clusterId: clusterSelected,
    enabled,
    date: { from: format(dates.startDate, 'yyyy-MM-dd'), to: format(dates.endDate, 'yyyy-MM-dd') },
  }

  const { isUserManager } = useCrmContext()
  const { data: goals } = useGoalsQuery({ ...variables })

  const { goalCanBeSet, quarterId } = useMemo(() => {
    let goalCanBeSet = false
    let quarterId = 0

    if (isUserManager) {
      quarters?.forEach((quarter) => {
        if (!quarter?.to || !quarter?.from || goalCanBeSet || isPast(parseUTC(quarter?.to))) {
          return
        }

        const isStartDateWithinQuarter = !isBefore(dates.startDate, parseUTC(quarter.from))

        const isEndDateWithinQuarter = !isAfter(new Date(format(dates.endDate, 'yyyy-MM-dd 00:00:00')), parseUTC(quarter.to))

        if (isStartDateWithinQuarter && isEndDateWithinQuarter) {
          goalCanBeSet = true
          quarterId = Number(quarter.id)
        }
      })
    }
    return { goalCanBeSet, quarterId }
  }, [quarters, dates, isUserManager])

  useEffect(() => {
    const goal = goals?.find(({ project_id }) => projectId.toString() === project_id.toString())?.goal ?? 0
    setGoalValue(goal.toString())
  }, [projectId, goals])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGoalValue(e.target.value)
  }

  const handleOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      createGoal({
        pharmacyClusterId: Number(clusterSelected),
        quarterId,
        goal: Number(goalValue),
        projectId,
      })
    }
  }

  const downloadCSV = (dataForDownload: DataForDownload, title: string) => {
    let csvData = ''

    if (title === 'Crm Termine') {
      csvData = generateCSVDataCRMAppointments(dataForDownload.appointmentsForDownload)
    } else if (title === 'Terminierte Apotheken') {
      csvData = generateCSVDataPharmacies(dataForDownload.pharmaciesForDownload)
    } else if (title === 'Schulungsdurchgänge') {
      csvData = generateCSVDataTrainings(dataForDownload.finishedPresentationsForDownload)
    } else if (title === 'Slots') {
      csvData = generateCSVDataTrainings(dataForDownload.slotsForDownload)
    }

    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = `${title}.csv`

    document.body.appendChild(a)
    a.click()

    window.URL.revokeObjectURL(url)
  }

  const handleDownloadClick = () => {
    if (dataForDownload) {
      downloadCSV(dataForDownload, title)
    } else {
      return
    }
  }

  return (
    <div className="w-1/2 lg:w-1/3 xl:w-1/5 border-r border-b px-3 pb-3 pt-2 xl:px-3 xl:pb-3 xl:pt-2">
      {enabled && (
        <div className="flex w-full items-center justify-between mb-2">
          {goalCanBeSet ? (
            <input className="w-1/3 mr-2 border-2 p-1" type="number" value={goalValue} onChange={handleOnChange} onKeyDown={handleOnEnter} />
          ) : (
            <span className="mr-2">{goalValue}</span>
          )}
          <div className="text-xs">Ziel der geschulten Apotheken pro Quartal</div>
        </div>
      )}
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center uppercase text-base h-10">
          <span style={{ overflowWrap: 'anywhere' }}>{title}</span>
        </div>
        {onDownloadClick && clusterSelected !== WHOLE_GERMANY_ID && (
          <IconButton icon={<DownloadIcon />} onClick={handleDownloadClick} kind={IconButtonKind.default} />
        )}
      </div>
      <div className="flex">
        {summaryValue !== undefined && <div className="text-xl font-bold">{summaryValue}</div>}
        {denominatorValue !== undefined && <div className="text-xl whitespace-pre-wrap text-gray-700"> / {denominatorValue}</div>}
      </div>
      <div className="flex text-xs">
        <div className="flex flex-col text-right mr-2">
        {data.map(({ value, textColorClass }, idx) => (
            <div key={idx} className={textColorClass || ''}>
              {value}
            </div>
          ))}
        </div>
        <div className="flex flex-col">
          {data.map(({ label }, idx) => (
            <div key={idx}>{label}</div>
          ))}
        </div>
      </div>
    </div>
  )
}
